import React from "react";
import { useGlobalContext } from "../../context/Context";
import { basename } from "../../config";
export default function Anti() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl text-[#1b3a91] font-bold">
                การต่อต้านทุจริตและคอร์รัปชั่น
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <img src={`${basename}/corporate-1.png`} class="corporate-dot-1" />
                  <div class="page-header text-center">
                    <p>นโยบายการต่อต้านการคอร์รัปชั่น</p>
                  </div>
                  <img src={`${basename}/anti-coruption.jpg`} class="corporate-pic" />
                </div>
                <div class="">
                  <div class="corporate-dot-2"></div>
                  <p class="text-skn-standard corporate-t1 mb-8">
                    บริษัทให้ความสำคัญในเรื่องการต่อต้านการคอร์รัปชั่น
                    โดยดำเนินธุรกิจและบริหารงานด้วยความโปร่งใส ยึดมั่นในคุณธรรม
                    จริยธรรม และความถูกต้อง
                    ซึ่งจัดให้มีแนวทางในการประพฤติปฏิบัติที่เหมาะสมของกรรมการ
                    ผู้บริหาร
                    และพนักงานในข้อพึงปฏิบัติทางธุรกิจและจริยธรรมทางธุรกิจ
                    รวมถึงการสนับสนุนกิจกรรมที่ส่งเสริมและปลูกฝังให้ผู้บริหารและพนักงานปฏิบัติตามกฎหมายและระเบียบข้อบังคับของบริษัทที่เกี่ยวข้อง
                    ไม่สนับสนุนให้มีการสร้างความสำเร็จของงานด้วยวิธีการทุจริต
                    ทั้งนี้
                    คณะกรรมการบริษัทได้กำหนดนโยบายเกี่ยวกับการต่อต้านการคอร์รัปชั่น
                    เพื่อเป็นแนวทางในการปฏิบัติ ดังนี้
                  </p>

                  <p class="text-skn-standard corporate-t1 t3">
                    1. ไม่ดำเนินการหรือเข้าไปมีส่วนร่วมในการคอร์รัปชั่นทุกรูปแบบ
                    ทั้งทางตรงและทางอ้อม
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    2. กรรมการ ผู้บริหาร
                    และพนักงานจะไม่กระทำหรือสนับสนุนการคอร์รัปชั่นไม่ว่ากรณีใดๆ
                    และต่อต้านการคอร์รัปชั่นอย่างเคร่งครัด
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    3. กรรมการ ผู้บริหาร
                    และพนักงานมีหน้าที่ปฏิบัติตามนโยบายการกำกับดูแลกิจการที่ดีและการต่อต้านการคอร์รัปชั่นโดยคณะกรรมการบริษัทมอบหมายให้ฝ่ายบริหารนำมาตรการต่อต้านการคอร์รัปชั่นไปสื่อสารและปฏิบัติตาม
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    4. ห้ามมิให้กรรมการบริษัท ผู้บริหาร และพนักงานกระทำการใดๆ
                    อันเป็นการเรียกร้อง หรือยอมรับทรัพย์สิน หรือผลประโยชน์อื่นใด
                    สำหรับตนเองหรือผู้อื่นที่ส่อไปในทางจูงใจให้ปฏิบัติ
                    หรือละเว้นการปฏิบัติหน้าที่มิชอบ
                    หรืออาจทำให้บริษัทเสียประโยชน์อันชอบธรรม
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    5. ห้ามมิให้กรรมการบริษัท ผู้บริหาร
                    และพนักงานให้หรือเสนอที่จะให้ทรัพย์สิน
                    หรือผลประโยชน์อื่นใดแก่บุคคลภายนอกเพื่อจูงใจให้บุคคลนั้นกระทำหรือละเว้นกระทำใดที่ผิดต่อกฎหมายหรือโดยมิชอบต่อตำแหน่งหน้าที่ของตน
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    6. สร้างวัฒนธรรมองค์กรที่ซื่อสัตย์และยึดมั่นในความเป็นธรรม
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    7. จัดให้มีการฝึกอบรมแก่พนักงานภายในองค์กร
                    เพื่อส่งเสริมให้พนักงานมีความซื่อสัตย์สุจริตต่อหน้าที่
                    และพร้อมจะนำหลักการและจรรยาบรรณในนโยบายการกำกับดูแลกิจการที่ดีเป็นหลักปฏิบัติในการดำเนินงานด้วยความเคร่งครัดทั้งองค์กร
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    8.
                    บริษัทจัดให้มีกระบวนการบริหารงานบุคลากรที่สะท้อนถึงความมุ่งมั่นของบริษัทต่อมาตรการต่อต้านการคอร์รัปชั่นตั้งแต่การคัดเลือก
                    การอบรม การประเมินผลงาน การให้ผลตอบแทน และการเลื่อนตำแหน่ง
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    9. บริษัทจัดให้มีการตรวจสอบภายใน
                    เพื่อให้มั่นใจว่าระบบการควบคุมภายในช่วยให้บริษัทบรรลุเป้าหมายได้
                    รวมทั้งตรวจสอบการปฏิบัติงานของทุกหน่วยงานให้เป็นไปตามข้อกำหนด
                    กฎระเบียบ และช่วยค้นหาข้อบกพร่องจุดอ่อน
                    และให้คำแนะนำในการพัฒนาระบบการปฏิบัติงานให้มีประสิทธิภาพและประสิทธิผลตามแนวทางการกำกับดูแลกิจการที่ดี
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    10.
                    ให้ความร่วมมือกับภาครัฐในการกำหนดให้ทุกหน่วยงานที่เกี่ยวข้องซึ่งติดต่อกับภาครัฐ
                    เปิดเผยแบบแสดงบัญชีรายรับ – รายจ่าย
                    ต่อสำนักงานคณะกรรมการป้องกันและปราบปรามทุจริตแห่งชาติ
                    (ป.ป.ช.)
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    11. กำหนดให้เลขานุการบริษัท ผู้จัดการตรวจสอบภายใน
                    หรือบุคคลอื่นใดที่คณะกรรมการตรวจสอบมอบหมายตามความเหมาะสม
                    เป็นบุคคลที่ทำหน้าที่ให้เกิดการกำกับดูแลกิจการที่ดี
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-3xl text-[#1b3a91] font-bold">
                Anti-corruption
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <img src={`${basename}/corporate-1.png`} class="corporate-dot-1" />
                  <div class="page-header text-center">
                    <p>Anti-corruption policy</p>
                  </div>
                  <img src={`${basename}/anti-coruption.jpg`}class="corporate-pic" />
                </div>
                <div class="">
                  <div class="corporate-dot-2"></div>
                  <p class="text-skn-standard corporate-t1 mb-8">
                    The Company prioritized about Anti-corruption and operated
                    the business with transparent management, hold the
                    principles of morals, ethical, and fairness. The Company
                    also has a Code of Conduct to be appropriate practice for
                    directors, executives, and employees as well as support
                    activities that promote and educate executives and employees
                    to act according to the law and companies’ related rules and
                    regulations. The Company does not promote in having success
                    from any dishonesty or corruption. Thus, the board members
                    of the Company have appointed ‘Anti-Corruption’ policy to be
                    a guideline for practice as follow.
                  </p>

                  <p class="text-skn-standard corporate-t1 t3">
                    1. To not operates or takes part in any form of corruption,
                    whether it is direct or indirect channels.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    2. Board members, executives, and employees will not engage
                    or support any forms of corruption and will strictly
                    prohibit any corruption acts.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    3. Board members, executives, and employees have
                    responsibility to operate according to the good governance
                    policy that prohibits any corruption. The Company board
                    members will appoint executives to create ‘Anti-Corruption’
                    measurement to communicate and act upon.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    4. It is prohibiting for all Company board members,
                    executives, and employees to engage in any act that involves
                    demanding or accepting assets or any own benefits or others,
                    or to wrongfully neglect their responsibilities that could
                    lead to the Company’s loss of its own legitimate benefits.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    5. It is prohibiting for all Company board members,
                    executives and employees to offer or present any asset or
                    benefits to other individual to persuade them to act or
                    neglect to the law or toward their own responsibilities.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    6. To creates an honest organization culture and hold to a
                    fair culture in the organization.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    7. To creates employees’ training within the organization to
                    promote employees to be honest toward their responsibilities
                    and willing to bring the principles and morals in the policy
                    to strictly operate a business as the main principle for the
                    entire organization.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    8. The Company will create employees management process to
                    reflect the determination of the Company toward
                    Anti-corruption measurement from the selecting of employees,
                    training, to performance evaluation, bonus and
                    compensations, and position promotions.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    9. The Company will create internal investigation to ensure
                    that the internal system will help the Company to achieve
                    the target. The Company will also investigate the operations
                    of all sectors to be according to rules and regulations and
                    together help seek for any faults and will provide
                    suggestions to develop effective and efficient operation
                    system based on the approach to maintaining good governance
                    of the organization.
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    10. The Company will cooperate with government sector in
                    appoint all related parties with the govern to disclosed any
                    accounts to Office of the National Anti-Corruption
                    Commission (NACC).
                  </p>
                  <p class="text-skn-standard corporate-t1 t3">
                    11.To appoints the Company secretary, internal audit or any
                    other individual that the board members have appointed to
                    help support corporate governance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
