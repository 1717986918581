import React from "react";
import "../../CSS/FkCss/ContactUs.css";
import { useGlobalContext } from "../../context/Context";
import { Link } from "react-router-dom";

export default function Contact() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                ติดต่อเรา
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <div class="page-header text-center">
                    <p>บริษัท ส.กิจชัย เอ็นเตอร์ไพรส์ จำกัด (มหาชน)</p>
                  </div>

                  <div class="cover-map">
                    <div class="contact-dot-4"></div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d4627.924007888376!2d101.59966793369637!3d12.739190877591687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d12.7411953!2d101.6002182!5e0!3m2!1sen!2sth!4v1463474204292"
                      height={400}
                      frameborder={0}
                      style={{ border: 0, width: "100%" }}
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>

                <div className="contact-cover">
                  <div className="contact-icon">
                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-1.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Call Us</p>
                        <p class="text-skn-standard contact-t2">0-3892-8188</p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-2.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Address</p>
                        <p class="text-skn-standard contact-t2">
                          99/9 หมู่ 7 ต.ห้วยยาง อ.แกลง จ.ระยอง 21110.
                        </p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-3.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Email</p>
                        <p class="text-skn-standard contact-t2">
                          skn@kijchai.co.th
                        </p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-4.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Website</p>
                        <p class="text-skn-standard contact-t2">
                          <Link to={"/"} target="_blank">
                            http://www.skn.co.th
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="form-contact mx-auto max-w-2xl">
                    <div class="contact-dot-5"></div>
                    <form>
                      <input
                        type="text"
                        placeholder="โปรดระบุทั้งชื่อและนามสกุลให้ชัดเจน"
                        className="input-contact"
                        required
                      />
                      <input
                        type="text"
                        placeholder="โปรดระบุอีเมล์ของท่าน"
                        className="input-contact"
                        required
                      />
                      <input
                        type="text"
                        placeholder="โปรดระบุเรื่องที่ต้องการติดต่อเรา"
                        className="input-contact"
                        required
                      />
                      <textarea
                        type="text"
                        placeholder="โปรดกรอกรายละเอียดที่ต้องการติดต่อเรา"
                        required
                      />
                      <div className="contact-dot"></div>
                      <div className="contant-btn mx-auto">
                        <button className="btn-blue">ส่งข้อมูล</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1 text-xl">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                Contact Us
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <div class="page-header text-center">
                    <p>S.Kijchai Enterprise Public Company Limited</p>
                  </div>

                  <div class="cover-map">
                    <div class="contact-dot-4"></div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d4627.924007888376!2d101.59966793369637!3d12.739190877591687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d12.7411953!2d101.6002182!5e0!3m2!1sen!2sth!4v1463474204292"
                      height={400}
                      frameborder={0}
                      style={{ border: 0, width: "100%" }}
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>

                <div className="contact-cover">
                  <div className="contact-icon">
                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-1.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Call Us</p>
                        <p class="text-skn-standard contact-t2">0-3892-8188</p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-2.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Address</p>
                        <p class="text-skn-standard contact-t2">
                          99/9 Moo 7 Huaiyang, Klaeng Rayong 21110.
                        </p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-3.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Email</p>
                        <p class="text-skn-standard contact-t2">
                          skn@kijchai.co.th
                        </p>
                      </div>
                    </div>

                    <div class="cover-pic-text-contact">
                      <div class="cover-pic-contact">
                        <img src="icon-contact-4.png" />
                      </div>
                      <div class="cover-text-contact">
                        <p class="text-skn-standard contact-t1">Website</p>
                        <p class="text-skn-standard contact-t2">
                          <Link to={"/"} target="_blank">
                            http://www.skn.co.th
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="form-contact mx-auto max-w-2xl">
                    <div class="contact-dot-5"></div>
                    <form>
                      <input
                        type="text"
                        placeholder="Please Enter Your Full Name"
                        className="input-contact"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Please Enter Your Email"
                        className="input-contact"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Please Specify the Preferred Offer"
                        className="input-contact"
                        required
                      />
                      <textarea
                        type="text"
                        placeholder="Please Complete the Propsed Requirements"
                        required
                      />
                      <div className="contact-dot"></div>
                      <div className="contant-btn mx-auto">
                        <button className="btn-blue">Send</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
