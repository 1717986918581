//ไม่ใช้
export const FinancialDownloadData = [
  {
    id: 0,
    namefn: "ไตรมาสที่ 1/2567 (สอบทานแล้ว)",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6608331.zip",
    year: "2567",
    yearEN: "2024",
  },
  {
    id: 1,
    namefn: "ไตรมาสที่ 2/2566 (สอบทานแล้ว)",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6608331.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 2,
    namefn: "ไตรมาสที่ 1/2566 (สอบทานแล้ว)",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6547253.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 3,
    namefn: "ประจำปี 2565 (สอบทานแล้ว)",
    //datefn: "27 ก.พ. 2566",
    filefn: "zip/T/6493312.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 4,
    namefn: "ไตรมาสที่ 3/2565 (สอบทานแล้ว)",
    //datefn: "11 พ.ย. 2565",
    filefn: "zip/T/6323972.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 5,
    namefn: "ไตรมาสที่ 2/2565 (สอบทานแล้ว)",
    //datefn: "11 ส.ค. 2565",
    filefn: "zip/T/6250061.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 6,
    namefn: "ไตรมาสที่ 1/2565 (สอบทานแล้ว)",
    //datefn: "11 พ.ค. 2565",
    filefn: "zip/T/6183300.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 7,
    namefn: "ประจำปี 2564 (ตรวจสอบแล้ว)",
    //datefn: "23 ก.พ. 2565",
    filefn: "zip/T/6122239.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 8,
    namefn: "ไตรมาสที่ 3/2564 (สอบทานแล้ว)",
    //datefn: "10 พ.ย. 2564",
    filefn: "zip/T/5952109.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 9,
    namefn: "ไตรมาสที่ 2/2564 (สอบทานแล้ว)",
    //datefn: "10 ส.ค. 2564",
    filefn: "zip/T/5897025.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 10,
    namefn: "ไตรมาสที่ 1/2564 (สอบทานแล้ว)",
    //datefn: "13 พ.ค. 2564",
    filefn: "zip/T/5742798.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 11,
    namefn: "ประจำปี 2563 (ตรวจสอบแล้ว)",
    //datefn: "25 ก.พ. 2564",
    filefn: "zip/T/5665968.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 12,
    namefn: "ไตรมาสที่ 3/2563 (สอบทานแล้ว)",
    //datefn: "16 พ.ย. 2563",
    filefn: "zip/T/5584817.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 13,
    namefn: "ไตรมาสที่ 2/2563 (สอบทานแล้ว)",
    //datefn: "11 ส.ค. 2563",
    filefn: "zip/T/5498895.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 14,
    namefn: "ไตรมาสที่ 1/2563 (สอบทานแล้ว)",
    //datefn: "15 พ.ค. 2563",
    filefn: "zip/T/5425946.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 15,
    namefn: "รายปี 2562 (ตรวจสอบแล้ว)",
    //datefn: "26 ก.พ. 2563",
    filefn: "zip/T/5339173.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 16,
    namefn: "ไตรมาสที่ 3/2562 (สอบทานแล้ว)",
    //datefn: "14 พ.ย. 2562",
    filefn: "zip/T/5242187.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 17,
    namefn: "ไตรมาสที่ 2/2562 (สอบทานแล้ว)",
    //datefn: "14 ส.ค. 2562",
    filefn: "zip/T/5153631.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 18,
    namefn: "ไตรมาสที่ 1/2562 (สอบทานแล้ว)",
    //datefn: "15 พ.ค. 2562",
    filefn: "zip/T/5080507.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 19,
    namefn: "รายปี 2561 (ตรวจสอบแล้ว)",
    //datefn: "27 ก.พ. 2562",
    filefn: "zip/T/5009882.zip",
    year: "2562",
    yearEN: "2018",
  },
  {
    id: 20,
    namefn: "ไตรมาสที่ 3/2561 (สอบทานแล้ว)",
    //datefn: "09 พ.ย. 2561",
    filefn: "zip/T/4597781.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 21,
    namefn: "ไตรมาสที่ 2/2561 (สอบทานแล้ว)",
    //datefn: "09 ส.ค. 2561",
    filefn: "zip/T/4522968.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 22,
    namefn: "ไตรมาสที่ 1/2561 (สอบทานแล้ว)",
    //datefn: "14 พ.ค. 2561",
    filefn: "zip/T/4456370.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 23,
    namefn: "รายปี 2560 (ตรวจสอบแล้ว)",
    //datefn: "26 ก.พ. 2561",
    filefn: "zip/T/4393190.zip",
    year: "2561",
    yearEN: "2017",
  },
  {
    id: 24,
    namefn: "ไตรมาสที่ 3/2560 (สอบทานแล้ว)",
    //datefn: "14 พ.ย. 2560",
    filefn: "zip/T/4314108.zip",
    year: "2560",
    yearEN: "2017",
  },
  {
    id: 25,
    namefn: "ไตรมาสที่ 2/2560 (สอบทานแล้ว)",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274600.zip",
    year: "2560",
    yearEN: "2017",
  },
  {
    id: 26,
    namefn: "รายปี 2559 (ตรวจสอบแล้ว)",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274411.zip",
    year: "2559",
    yearEN: "2016",
  },
];

export const FinancialDownloadDataTH = [
  {
    id: 0,
    namefn: "ไตรมาสที่ 1/2567",
    //datefn: "10 ส.ค. 2567",
    filefn: "zip/T/q12567_t.zip",
    year: "2567",
    yearEN: "2024",
  },
  {
    id: 1,
    namefn: "ประจำปี 2566",
    //datefn: "27 ก.พ. 2566",
    filefn: "zip/T/23024405.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 2,
    namefn: "ไตรมาสที่ 3/2566",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/1343FIN131120231709020634T.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 3,
    namefn: "ไตรมาสที่ 2/2566",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6608331.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 4,
    namefn: "ไตรมาสที่ 1/2566",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6547253.zip",
    year: "2566",
    yearEN: "2023",
  },
  {
    id: 5,
    namefn: "ประจำปี 2565",
    //datefn: "27 ก.พ. 2566",
    filefn: "zip/T/6493312.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 6,
    namefn: "ไตรมาสที่ 3/2565",
    //datefn: "11 พ.ย. 2565",
    filefn: "zip/T/6323972.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 7,
    namefn: "ไตรมาสที่ 2/2565",
    //datefn: "11 ส.ค. 2565",
    filefn: "zip/T/6250061.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 8,
    namefn: "ไตรมาสที่ 1/2565",
    //datefn: "11 พ.ค. 2565",
    filefn: "zip/T/6183300.zip",
    year: "2565",
    yearEN: "2022",
  },
  {
    id: 9,
    namefn: "ประจำปี 2564",
    //datefn: "23 ก.พ. 2565",
    filefn: "zip/T/6122239.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 10,
    namefn: "ไตรมาสที่ 3/2564",
    //datefn: "10 พ.ย. 2564",
    filefn: "zip/T/5952109.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 11,
    namefn: "ไตรมาสที่ 2/2564",
    //datefn: "10 ส.ค. 2564",
    filefn: "zip/T/5897025.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 12,
    namefn: "ไตรมาสที่ 1/2564",
    //datefn: "13 พ.ค. 2564",
    filefn: "zip/T/5742798.zip",
    year: "2564",
    yearEN: "2021",
  },
  {
    id: 13,
    namefn: "ประจำปี 2563",
    //datefn: "25 ก.พ. 2564",
    filefn: "zip/T/5665968.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 14,
    namefn: "ไตรมาสที่ 3/2563",
    //datefn: "16 พ.ย. 2563",
    filefn: "zip/T/5584817.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 15,
    namefn: "ไตรมาสที่ 2/2563",
    //datefn: "11 ส.ค. 2563",
    filefn: "zip/T/5498895.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 16,
    namefn: "ไตรมาสที่ 1/2563",
    //datefn: "15 พ.ค. 2563",
    filefn: "zip/T/5425946.zip",
    year: "2563",
    yearEN: "2020",
  },
  {
    id: 17,
    namefn: "รายปี 2562",
    //datefn: "26 ก.พ. 2563",
    filefn: "zip/T/5339173.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 18,
    namefn: "ไตรมาสที่ 3/2562",
    //datefn: "14 พ.ย. 2562",
    filefn: "zip/T/5242187.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 19,
    namefn: "ไตรมาสที่ 2/2562",
    //datefn: "14 ส.ค. 2562",
    filefn: "zip/T/5153631.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 20,
    namefn: "ไตรมาสที่ 1/2562",
    //datefn: "15 พ.ค. 2562",
    filefn: "zip/T/5080507.zip",
    year: "2562",
    yearEN: "2019",
  },
  {
    id: 21,
    namefn: "รายปี 2561",
    //datefn: "27 ก.พ. 2562",
    filefn: "zip/T/5009882.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 22,
    namefn: "ไตรมาสที่ 3/2561",
    //datefn: "09 พ.ย. 2561",
    filefn: "zip/T/4597781.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 23,
    namefn: "ไตรมาสที่ 2/2561",
    //datefn: "09 ส.ค. 2561",
    filefn: "zip/T/4522968.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 24,
    namefn: "ไตรมาสที่ 1/2561",
    //datefn: "14 พ.ค. 2561",
    filefn: "zip/T/4456370.zip",
    year: "2561",
    yearEN: "2018",
  },
  {
    id: 25,
    namefn: "รายปี 2560",
    //datefn: "26 ก.พ. 2561",
    filefn: "zip/T/4393190.zip",
    year: "2560",
    yearEN: "2017",
  },
  {
    id: 26,
    namefn: "ไตรมาสที่ 3/2560",
    //datefn: "14 พ.ย. 2560",
    filefn: "zip/T/4314108.zip",
    year: "2560",
    yearEN: "2017",
  },
  {
    id: 27,
    namefn: "ไตรมาสที่ 2/2560",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274600.zip",
    year: "2560",
    yearEN: "2017",
  },
  {
    id: 28,
    namefn: "รายปี 2559",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274411.zip",
    year: "2559",
    yearEN: "2016",
  },
];

export const FinancialDownloadDataEN = [
  {
    id: 0,
    namefn: "Quarter 1/2024",
    // //datefn: "10 ส.ค. 2567",
    filefn: "zip/T/q12567_e.zip",
    year: "2024",
  },
  {
    id: 1,
    namefn: "Annual 2023",
    //datefn: "27 ก.พ. 2566",
    filefn: "zip/E/23024480.zip",
    year: "2023",
  },
  {
    id: 2,
    namefn: "Quarter 3/2023",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/E/1343FIN131120231709020766E.zip",
    year: "2023",
  },
  {
    id: 3,
    namefn: "Quarter 2/2023",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6608331.zip",
    year: "2023",
  },
  {
    id: 4,
    namefn: "Quarter 1/2023",
    //datefn: "10 ส.ค. 2566",
    filefn: "zip/T/6547253.zip",
    year: "2023",
  },
  {
    id: 5,
    namefn: "Annual 2022",
    //datefn: "27 ก.พ. 2566",
    filefn: "zip/T/6493312.zip",
    year: "2022",
  },
  {
    id: 6,
    namefn: "Quarter 3/2022",
    //datefn: "11 พ.ย. 2565",
    filefn: "zip/T/6323972.zip",
    year: "2022",
  },
  {
    id: 7,
    namefn: "Quarter 2/2022",
    //datefn: "11 ส.ค. 2565",
    filefn: "zip/T/6250061.zip",
    year: "2022",
  },
  {
    id: 8,
    namefn: "Quarter 1/2022",
    //datefn: "11 พ.ค. 2565",
    filefn: "zip/T/6183300.zip",
    year: "2022",
  },
  {
    id: 9,
    namefn: "Annual 2021",
    //datefn: "23 ก.พ. 2565",
    filefn: "zip/T/6122239.zip",
    year: "2021",
  },
  {
    id: 10,
    namefn: "Quarter 3/2021",
    //datefn: "10 พ.ย. 2564",
    filefn: "zip/T/5952109.zip",
    year: "2021",
  },
  {
    id: 11,
    namefn: "Quarter 2/2021",
    //datefn: "10 ส.ค. 2564",
    filefn: "zip/T/5897025.zip",
    year: "2021",
  },
  {
    id: 12,
    namefn: "Quarter 1/2021",
    //datefn: "13 พ.ค. 2564",
    filefn: "zip/T/5742798.zip",
    year: "2021",
  },
  {
    id: 13,
    namefn: "Annual 2020",
    //datefn: "25 ก.พ. 2564",
    filefn: "zip/T/5665968.zip",
    year: "2020",
  },
  {
    id: 14,
    namefn: "Quarter 3/2020",
    //datefn: "16 พ.ย. 2563",
    filefn: "zip/T/5584817.zip",
    year: "2020",
  },
  {
    id: 15,
    namefn: "Quarter 2/2020",
    //datefn: "11 ส.ค. 2563",
    filefn: "zip/T/5498895.zip",
    year: "2020",
  },
  {
    id: 16,
    namefn: "Quarter 1/2020",
    //datefn: "15 พ.ค. 2563",
    filefn: "zip/T/5425946.zip",
    year: "2020",
  },
  {
    id: 17,
    namefn: "Annual 2019",
    //datefn: "26 ก.พ. 2563",
    filefn: "zip/T/5339173.zip",
    year: "2019",
  },
  {
    id: 18,
    namefn: "Quarter 3/2019",
    //datefn: "14 พ.ย. 2562",
    filefn: "zip/T/5242187.zip",
    year: "2019",
  },
  {
    id: 19,
    namefn: "Quarter 2/2019",
    //datefn: "14 ส.ค. 2562",
    filefn: "zip/T/5153631.zip",
    year: "2019",
  },
  {
    id: 20,
    namefn: "Quarter 1/2019",
    //datefn: "15 พ.ค. 2562",
    filefn: "zip/T/5080507.zip",
    year: "2019",
  },
  {
    id: 21,
    namefn: "Annual 2018",
    //datefn: "27 ก.พ. 2562",
    filefn: "zip/T/5009882.zip",
    year: "2018",
  },
  {
    id: 22,
    namefn: "Quarter 3/2018",
    //datefn: "09 พ.ย. 2561",
    filefn: "zip/T/4597781.zip",
    year: "2018",
  },
  {
    id: 23,
    namefn: "Quarter 2/2018",
    //datefn: "09 ส.ค. 2561",
    filefn: "zip/T/4522968.zip",
    year: "2018",
  },
  {
    id: 24,
    namefn: "Quarter 1/2018",
    //datefn: "14 พ.ค. 2561",
    filefn: "zip/T/4456370.zip",
    year: "2018",
  },
  {
    id: 25,
    namefn: "Annual 2017",
    //datefn: "26 ก.พ. 2561",
    filefn: "zip/T/4393190.zip",
    year: "2017",
  },
  {
    id: 26,
    namefn: "Quarter 3/2017",
    //datefn: "14 พ.ย. 2560",
    filefn: "zip/T/4314108.zip",
    year: "2017",
  },
  {
    id: 27,
    namefn: "Quarter 2/2017",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274600.zip",
    year: "2017",
  },
  {
    id: 28,
    namefn: "Annual 2016",
    //datefn: "25 ก.ย. 2560",
    filefn: "zip/T/4274411.zip",
    year: "2016",
  },
];
