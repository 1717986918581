import React, { useState } from "react";
import {
  irCalendarData,
  irCalendarDataEN,
} from "../../../mock/MockupIRCalendar";
import { FaFileAlt } from "react-icons/fa";
import { Header } from "../../../Components";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function IRCalendar() {
  const { language } = useGlobalContext();
  const [relatePdf, setRelatePdf] = useState("");
  const [year, setYear] = useState(2566);
  const [yearEN, setYearEN] = useState(2023);
  // const listToShow = irCalendarData.filter((e) => e.year == year);
  const seenYears = new Set();
  const seenYearsEN = new Set();
  console.log(year);

  return (
    <div className="sub-top-hight">
      <div className="container-I" style={{ marginBottom: 100 }}>
        <div className="row">
          <div className="box-line-c">
            <Header
              nameTH={"ปฏิทินกิจกรรมนักลงทุนสัมพันธ์"}
              nameEN={"IR Calendar"}
            />
          </div>
        </div>
        <div
          className="container mt-4"
          style={{ margin: "auto", marginTop: "3.75rem" }}
        >
          <div className="select-year">
            <p className="select-1">
              {language === " TH" ? "เลือกปี:" : " Select Year:"}
            </p>
            <select
              className="select-box select-1"
              style={{ paddingLeft: 10 }}
              value={language === "T" ? year : yearEN}
              onChange={(e) => {
                language === "T"
                  ? setYear(e.target.value)
                  : setYearEN(e.target.value);
              }}
            >
              {language === "T"
                ? irCalendarData.map((item, i) => {
                    if (!seenYears.has(item.year)) {
                      seenYears.add(item.year);

                      return (
                        <option key={i} value={item.year}>
                          {item.year}
                        </option>
                      );
                    }
                  })
                : irCalendarDataEN.map((item, i) => {
                    if (!seenYearsEN.has(item.year)) {
                      seenYearsEN.add(item.year);

                      return (
                        <option key={i} value={item.year}>
                          {item.year}
                        </option>
                      );
                    }
                  })}

              {/* <option value="2566" onClick={() => setYear(2566)}>
                  2566
                </option>
                <option value="2565" onClick={() => setYear(2565)}>
                  2565
                </option>
                <option value="2564" onClick={() => setYear(2564)}>
                  2564
                </option>
                <option value="2563" onClick={() => setYear(2563)}>
                  2563
                </option>
                <option value="2562" onClick={() => setYear(2562)}>
                  2562
                </option>
                <option value="2561" onClick={() => setYear(2561)}>
                  2561
                </option> */}
            </select>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full table-respon mt-4 text-sm text-center text-white">
              <thead className="text-base text-white bg-[#1b3a91] border-b-2 border-[#84a5ff]">
                {language === "T" ? (
                  <tr>
                    <th scope="col" className="calendar-tb-1 py-5 text-center">
                      วันและเวลา
                    </th>
                    <th scope="col" className="calendar-tb-2 py-5">
                      กิจกรรม
                    </th>
                    <th scope="col" className="calendar-tb-3 py-5">
                      สถานที่
                    </th>
                    <th scope="col" className="calendar-tb-4 py-5">
                      เอกสารประกอบ
                    </th>
                  </tr>
                ) : (
                  <tr>
                    <th scope="col" className="calendar-tb-1 py-5 text-center">
                      Date & Time
                    </th>
                    <th scope="col" className="calendar-tb-2 py-5">
                      Event
                    </th>
                    <th scope="col" className="calendar-tb-3 py-5">
                      Location
                    </th>
                    <th scope="col" className="calendar-tb-4 py-5">
                      Relate Info
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {language === "T"
                  ? irCalendarData.map((item, i) => {
                      console.log(year);
                      if (item.year == year) {
                        return (
                          <tr
                            key={i}
                            className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                          >
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.date}
                              <br />
                              {item.time}
                            </td>
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.event}
                            </td>
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.location}
                            </td>
                            <td className="icon-pdf">
                              {item.relateInfo ? (
                                <Link
                                  to={`/ReadDetail/${item.relateInfo}`}
                                  onClick={() => setRelatePdf(item.relateInfo)}
                                >
                                  <FaFileAlt />
                                </Link>
                              ) : (
                                <p>-</p>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })
                  : irCalendarDataEN.map((item, i) => {
                      console.log(year);
                      if (item.year == yearEN) {
                        return (
                          <tr
                            key={i}
                            className="bg-slate-100/60 border-b hover:bg-slate-100/800 transition-all text-base"
                          >
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.date}
                              <br />
                              {item.time}
                            </td>
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.event}
                            </td>
                            <td className="px-6 py-3 text-[#666] text-left">
                              {item.location}
                            </td>
                            <td className="icon-pdf">
                              {item.relateInfo ? (
                                <Link
                                  to={`/ReadDetail/${item.relateInfo}`}
                                  onClick={() => setRelatePdf(item.relateInfo)}
                                >
                                  <FaFileAlt />
                                </Link>
                              ) : (
                                <p>-</p>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })}
              </tbody>
            </table>
          </div>
          <div className="p-number">
            <a href="#" className="border-cal">
              &lt;&lt;
            </a>
            <div
              className="border-cal"
              style={{ color: "white", backgroundColor: "var(--clr-blue)" }}
            >
              1
            </div>
            <a href="#" className="border-cal">
              &gt;&gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
