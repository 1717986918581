import React from "react";
import { useGlobalContext } from "../../../context/Context";
import { basename } from "../../../config";

export default function FinancialHiglights() {
  const { language } = useGlobalContext();
  return (
    <>
      {language === "T" ? (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                ข้อมูลสำคัญทางการเงิน
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <div class="mt-12 mb-8 text-center">
                    <p className="font-bold text-[#1b3a91]">
                      สรุปผลการดำเนินงานและฐานะทางการเงินที่สำคัญ
                    </p>
                  </div>
                  <div className="mx-0 sm:mx-20">
                    <img
                      src={`${basename}/financial-highlights-pic.jpg`}
                      class="corporate-pic"
                    />
                  </div>
                  {/* table */}
                  <div class="container mt-4">
                    <div className="flex justify-end">
                      <p class="text-sm text-[#1b3a91]">(หน่วย: ล้านบาท)</p>
                    </div>
                    <div class="relative overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-[#84a5ff] border-t border-t-[#d0d0d0]">
                          <tr>
                            <th
                              scope="col"
                              class="py-4 text-center text-[#1b3a91] bg-white border-b2 border-b-2 border-[#1b3a91]"
                            >
                              งวดงบการเงิน ณ วันที่
                            </th>
                            <th scope="col" class=" py-4 text-white">
                              งบปี 63
                              <br /> 31/12/2563
                            </th>
                            <th scope="col" class="py-4 text-white">
                              งบปี 64 <br />
                              31/12/2564
                            </th>
                            <th scope="col" class="py-4 text-white">
                              งบปี 65
                              <br /> 31/12/2565
                            </th>
                            <th scope="col" class="py-4 text-white">
                              งบปี 66
                              <br /> 31/12/2566
                            </th>
                            <th scope="col" class="py-4 text-white">
                              ไตรมาส 1/67
                              <br /> 31/03/2567
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-[#6178bf] border-b hover:bg-slate-100/800 transition-all text-base">
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-normal text-start"
                            >
                              บัญชีทางการเงินที่สำคัญ
                            </th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              สินทรัพย์รวม
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,628.66
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,118.19
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,428.08
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,841.56
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,556.65
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              หนี้สินรวม
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,243.21
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              956.06
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              808.95
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,085.52
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              638.04
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ส่วนของผู้ถือหุ้น
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2,385.45
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,162.13
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,619.13
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,756.04
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,918.61
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              มูลค่าหุ้นที่เรียกชำระแล้ว
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              รายได้รวม
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2,479.11
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,192.45
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,187.97
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,285.92
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              884.36
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              กำไรสุทธิ
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              163.08
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              776.68
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              682.39
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              424.75
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              162.57
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              กำไรต่อหุ้น (บาท)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.20
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.97
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.85
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.53
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.2
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="bg-[#6178bf] border-b hover:bg-slate-100/800 transition-all text-base">
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-normal text-start"
                            >
                              อัตราส่วนทางการเงินที่สำคัญ
                            </th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                          </tr>
                        </tbody>

                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ROA(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.68
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              23.28
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              17.38
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              10.23
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              13.27
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ROE(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.84
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              28.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              20.13
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              11.52
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              14.13
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              อัตรากำไรสุทธิ(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.58
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              18.53
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              16.29
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              12.93
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              18.38
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="relative mt-8 overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-b-[#84a5ff] border-t border-t-[#d0d0d0]">
                          <tr>
                            <th
                              scope="col"
                              class="py-8 text-center text-[#1b3a91] bg-white border-b2 border-b-2 border-[#1b3a91]"
                            >
                              ค่าสถิติสำคัญ ณ วันที่
                            </th>
                            <th scope="col" class=" py-4 text-white">
                              30/12/2563
                            </th>
                            <th scope="col" class="py-4 text-white">
                              30/12/2564
                            </th>
                            <th scope="col" class="py-4 text-white">
                              30/12/2565
                            </th>

                            <th scope="col" class="py-4 text-white">
                              28/12/2566
                            </th>
                            <th scope="col" class="py-4 text-white">
                              23/05/2567
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ราคาล่าสุด(บาท)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2.04
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.50
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.86
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.28
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.9
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              มูลค่าหลักทรัพย์ตามราคาตลาด
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,632.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5,200.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,888.00
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,424.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,720.00
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              วันที่ของงบการเงินที่ใช้คำนวณค่าสถิติ
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2563
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2564
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2565
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2566
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              31/03/2567
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              P/E (เท่า)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              11.60
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              10.44
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.78
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.74
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              8.8
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              P/BV (เท่า)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.66
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.83
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.13
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.94
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.2
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              มูลค่าหุ้นทางบัญชีต่อหุ้น (บาท)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.10
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.56
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.30
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.57
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.9
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              อัตราส่วนเงินปันผลตอบแทน (%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.50
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2.60
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.76
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              8.41
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.56
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mt-36 mb-8 lg:mt-44 container mx-auto px-4">
          <div className="box pl-4 md:pl-8 pr-8 py-4">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="p1">Welcome To S.Kijchai</h2>
              <h1 className="p2 text-4xl  text-[#1b3a91] font-bold">
                Financial Highlights
              </h1>
            </div>
          </div>

          <div class="cover-corporate">
            <div class="container">
              <div class="rows">
                <div class="col-xl-12">
                  <div class="mt-12 mb-8 text-center">
                    <p className="font-bold text-[#1b3a91]">
                      Highlight of Financial Performance
                    </p>
                  </div>
                  <div className="mx-0 sm:mx-20">
                    <img
                      src={`${basename}/financial-highlights-pic.jpg`}
                      class="corporate-pic"
                    />
                  </div>
                  {/* table */}
                  <div class="container mt-4">
                    <div className="flex justify-end">
                      <p class="text-sm text-[#1b3a91]">(Unit: M.Baht)</p>
                    </div>
                    <div class="relative overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-[#84a5ff] border-t border-t-[#d0d0d0]">
                          <tr>
                            <th
                              scope="col"
                              class="py-4 text-center text-[#1b3a91] bg-white border-b2 border-b-2 border-[#1b3a91]"
                            >
                              Period as of
                            </th>
                            <th scope="col" class=" py-4 text-white">
                              Y/E '20
                              <br /> 31/12/2020
                            </th>
                            <th scope="col" class="py-4 text-white">
                              Y/E '21 <br />
                              31/12/2021
                            </th>
                            <th scope="col" class="py-4 text-white">
                              Y/E '22
                              <br /> 31/12/2022
                            </th>
                            <th scope="col" class="py-4 text-white">
                              Y/E '23
                              <br /> 31/12/2023
                            </th>
                            <th scope="col" class="py-4 text-white">
                              Q 1/24
                              <br /> 31/03/2024
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-[#6178bf] border-b hover:bg-slate-100/800 transition-all text-base">
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-normal text-start"
                            >
                              Financial Data
                            </th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Assets
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,628.66
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,118.19
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,428.08
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,841.56
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,556.65
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Liabilities
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,243.21
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              956.06
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              808.95
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,085.52
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              638.04
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Equity
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2,385.45
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,162.13
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,619.13
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,756.04
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,918.61
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Paid-up Capital
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              800.00
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Revenue
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2,479.11
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,192.45
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,187.97
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,285.92
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              884.36
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Net Profit
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              163.08
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              776.68
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              682.39
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              424.75
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              162.57
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              EPS (Baht)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.20
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.97
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.85
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.53
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.2
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="bg-[#6178bf] border-b hover:bg-slate-100/800 transition-all text-base">
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-normal text-start"
                            >
                              Financial Ratio
                            </th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                            <th
                              scope="row"
                              class="px-6 py-4 text-white font-medium"
                            ></th>
                          </tr>
                        </tbody>

                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ROA(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.68
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              23.28
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              17.38
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              10.23
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              13.27
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              ROE(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.84
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              28.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              20.13
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              11.52
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              14.13
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Net Profit Margin(%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.58
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              18.53
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              16.29
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              12.93
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              18.38
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="relative mt-8 overflow-x-auto">
                      <table class="w-[600px] sm:w-full mt-4 text-sm text-center">
                        <thead class="text-md bg-[#1b3a91] border-b-2 border-b-[#84a5ff] border-t border-t-[#d0d0d0]">
                          <tr>
                            <th
                              scope="col"
                              class="py-8 text-center text-[#1b3a91] bg-white border-b2 border-b-2 border-[#1b3a91]"
                            >
                              Statistics as of
                            </th>
                            <th scope="col" class=" py-4 text-white">
                              30/12/2020
                            </th>
                            <th scope="col" class="py-4 text-white">
                              30/12/2021
                            </th>
                            <th scope="col" class="py-4 text-white">
                              30/12/2022
                            </th>

                            <th scope="col" class="py-4 text-white">
                              28/12/2023
                            </th>
                            <th scope="col" class="py-4 text-white">
                              23/05/2024
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Last Price(Baht)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2.04
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.50
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.86
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.28
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.9
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Market Cap.
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1,632.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5,200.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,888.00
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3,424.00
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4,720.00
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              F/S Period (As of date)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2020
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2021
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2022
                            </td>

                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              30/09/2023
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              31/03/2024
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              P/E
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              11.60
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              10.44
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.78
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              6.74
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              8.8
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              P/BV
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.66
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.83
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.13
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              0.94
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.2
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Book Value per share (Baht)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.10
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.56
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.30
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.57
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              4.9
                            </td>
                          </tr>

                          <tr class="bg-slate-100/60 border-b border-[#d0d0d0] hover:bg-slate-100/800 transition-all text-base hover:bg-slate-300/50">
                            <th
                              scope="row"
                              class="px-6 py-3 text-[#1b3a91] font-medium text-start text-sm"
                            >
                              Dvd. Yield (%)
                            </th>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              1.50
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              2.60
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              5.76
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              8.41
                            </td>
                            <td class="px-6 py-3 text-[#666] text-end text-sm">
                              3.56
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
