import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/Context";
import { Header } from "../../../Components";
import { getDateSetRealtime } from "../../../Service/Service";
import { BsGraphUpArrow } from "react-icons/bs";
import CardCountNumber from "../Components/CardCountNumber";

export default function StockPrice() {
  const { language } = useGlobalContext();

  const [dataTime, setDataTime] = useState(
    language === "E" ? "DD / MMMMM / YYYY / HH:MM" : "วว / ดดดดด / ปปปป / ซซ:นน"
  );
  const [dataSETRealtime, setDataSETRealtime] = useState({
    time: dataTime,
    high: 0,
    low: 0,
    last: 0,
    change: 0,
    percentChange: "",
    totalVolume: 0,
    totalValue: 0,
    prvTime: "",
  });

  useEffect(() => {
    let active = true;

    const getSetRealtime = async () => {
      const { status, result } = await getDateSetRealtime();
      if (active && status && result.data && result.data.time) {
        const now = new Date(result.data.time);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: language === "T" ? false : true,
        };
        const formattedDateTime = now.toLocaleDateString(
          language === "E" ? "en-EN" : "th-TH",
          options
        );

        setDataSETRealtime(result.data);
        setDataTime(formattedDateTime);
      }
    };

    getSetRealtime();
    return () => {
      active = false;
    };
  }, [language]);
  return (
    <div className="container-page">
      <div className="container-top">
        <Header nameTH={"ราคาหลักทรัพย์ล่าสุด"} nameEN={"Stock Price"} />
        {dataSETRealtime ? (
          <div className="container-main">
            <div className="content-stock-info">
              <div className="stock-info-left">
                <div className="card-textInfo">
                  <div className="content-about-info">
                    <BsGraphUpArrow />
                    <span className="sharesInfo">SKN</span>
                  </div>
                  {/* <hr /> */}
                  <div className="content-about-info">
                    <span className="t-s">
                      {language === "T" ? "ราคาล่าสุด" : "Last"}
                    </span>
                    <CardCountNumber
                      start={0}
                      end={dataSETRealtime?.last}
                      duration={1200}
                    />
                  </div>
                </div>
                <div className="card-content-4">
                  <hr />
                  {/* <div className="card-stock-textInfo">
                    <p className="t-xs">
                      {language === "E" ? "Last Update :" : "ปรับปรุงเมื่อ  :"}
                    </p>
                    <span className="t-xs">{dataTime} </span>
                  </div> */}
                </div>
              </div>
              <div className="stock-info-right">
                {/* <div className="card-textInfo">
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E" ? "Prior :" : "วันก่อนหน้า :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.historicalPrice?.last}
                    </p>
                  </div>
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E" ? "Close :" : "ราคาปิด :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.historicalPrice?.close}
                    </p>
                  </div>
                </div> */}

                <div className="card-textInfo">
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E" ? "Last Update :" : "ปรับปรุงเมื่อ  :"}
                    </p>
                    <p className="t-s">{dataTime}</p>
                  </div>
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E"
                        ? "Change (% Change) :"
                        : "เปลี่ยนแปลง (% เปลี่ยนแปลง) :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.change} (
                      {dataSETRealtime?.percentChange})
                    </p>
                  </div>
                </div>
                <hr />

                <div className="card-textInfo">
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E"
                        ? "Volume (Shares)"
                        : "ปริมาณซื้อขาย (หุ้น) :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.totalVolume.toLocaleString("en-US")}
                    </p>
                  </div>
                  <div className="content-about-info">
                    <p className="t-s">
                      {language === "E"
                        ? "Value (Baht) :"
                        : "มูลค่าการซื้อขาย :"}
                    </p>
                    <p className="t-xl">
                      {dataSETRealtime?.totalValue.toLocaleString("en-US")}
                    </p>
                  </div>{" "}
                  {/* <div className="content-about-info">
                    <p className="t-xs">
                      {language === "E" ? "Last Update :" : "ปรับปรุงเมื่อ  :"}
                    </p>
                    <p className="t-xl">{dataTime}</p>
                  </div> */}
                  {/* <div className="content-about-info"></div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-not-data">
            <div className="container-main">
              <div className="row-main">
                <div className="col-main">
                  <div className="flex-main-center">
                    <div className="text-not-data">
                      {language === "T"
                        ? "ไม่มีข้อมูล ณ ขณะนี้"
                        : "No Information Now"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
