import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  dataYoY,
  dataQoQ,
  dataYoYEN,
  dataQoQEN,
} from "../../../mock/MockupFinancial";
import { useGlobalContext } from "../../../context/Context";

export default function Financial() {
  const { language } = useGlobalContext();
  const [isSelectYoy, setIsSelectYoy] = useState(true);
  const [isSelectQoq, setIsSelectQoq] = useState(false);

  function handleFinancial(name) {
    if (name === "YoY") {
      if (isSelectYoy === false) {
        setIsSelectYoy(true);
        if (isSelectQoq === true) {
          setIsSelectQoq(false);
        }
      }
    } else {
      if (isSelectQoq === false) {
        setIsSelectQoq(true);
        if (isSelectYoy === true) {
          setIsSelectYoy(false);
        }
      }
    }
  }

  console.log("YoY,QoQ :", isSelectYoy, isSelectQoq);

  return (
    <div className="container-main-financial">
      <div className="content-row">
        <div className="main-ir-col">
          {language === "T" ? (
            <div className="cover-stock-price">
              <div className="content-row-financial">
                <div className="main-ir-col">
                  <p className="t-1">4,556.65</p>
                  <p className="t-2">สินทรัพย์รวม</p>
                  <p className="t-3">(ล้านบาท)</p>
                </div>
                <div className="main-ir-col">
                  <p className="t-1">638.04</p>
                  <p className="t-2">หนี้สินรวม</p>
                  <p className="t-3">(ล้านบาท)</p>
                </div>
              </div>
              <div className="content-row-financial">
                <div className="main-ir-col">
                  <p className="t-1">162.57</p>
                  <p className="t-2">กำไรสุทธิ</p>
                  <p className="t-3">(ล้านบาท)</p>
                </div>
                <div className="main-ir-col">
                  <p className="t-1">3,918.61</p>
                  <p className="t-2">ส่วนของผู้ถือหุ้น</p>
                  <p className="t-3">(ล้านบาท)</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="cover-stock-price">
              <div className="content-row-financial">
                <div className="main-ir-col">
                  <p className="t-1">4,556.65</p>
                  <p className="t-2">Assets</p>
                  <p className="t-3">(Million Baht)</p>
                </div>
                <div className="main-ir-col">
                  <p className="t-1">638.04</p>
                  <p className="t-2">Liabilities</p>
                  <p className="t-3">(Million Baht)</p>
                </div>
              </div>
              <div className="content-row-financial">
                <div className="main-ir-col">
                  <p className="t-1">162.57</p>
                  <p className="t-2">Net Profit</p>
                  <p className="t-3">(Million Baht)</p>
                </div>
                <div className="main-ir-col">
                  <p className="t-1">3,918.61</p>
                  <p className="t-2">Equity</p>
                  <p className="t-3">(Million Baht)</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="main-ir-col">
          <div className="cover-financial-highlights">
            <div className="head-financial">
              <p className="t-1">Financial Highlight</p>
            </div>
            <div className="main-box-chart">
              <div className="box-chart">
                {isSelectYoy && (
                  <div className="layout-box-chart">
                    <div className="main-chart">
                      <ReactApexChart
                        options={
                          language === "T" ? dataYoY.options : dataYoYEN.options
                        }
                        series={
                          language === "T" ? dataYoY.series : dataYoYEN.series
                        }
                        type="bar"
                        height={"100%"}
                        width={"100%"}
                      />
                    </div>
                  </div>
                )}
                {isSelectQoq && (
                  <div className="layout-box-chart">
                    <div className="main-chart">
                      <ReactApexChart
                        options={
                          language === "T" ? dataQoQ.options : dataQoQEN.options
                        }
                        series={
                          language === "T" ? dataQoQ.series : dataQoQEN.series
                        }
                        type="bar"
                        height={"100%"}
                        width={"100%"}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="tablist-financial">
                <button
                  className={`button-tablist ${
                    isSelectYoy ? "active" : ""
                  } financial-left`}
                  onClick={() => handleFinancial("YoY")}
                >
                  YoY
                </button>
                <button
                  className={`button-tablist ${
                    isSelectQoq ? "active" : ""
                  } financial-right`}
                  onClick={() => handleFinancial("QoQ")}
                >
                  QoQ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
