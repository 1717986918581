import React from "react";
import { useGlobalContext } from "../../../context/Context";
import { Link } from "react-router-dom";

export default function EmailContact() {
  const { language } = useGlobalContext();
  return (
    <div className="container-content-email">
      <div className="container-main">
        {language === "T" ? (
          <div className="content-row layout-email">
            <div className="col-66">
              <div className="email-contact-text">
                <p className="t1">อีเมล์รับข่าวสาร & ติดต่อนักลงทุนสัมพันธ์</p>
                <p className="t2">
                  ส่วนข้อมูลสำหรับการติดต่อกับนักลงทุนสัมพันธ์ของ บริษัท
                  ส.กิจชัย เอ็นเตอร์ไพรส์ จํากัด (มหาชน)
                  หากท่านมีคำถามหรือคำแนะนำใดๆ
                  หรือสมัครเพื่อร่วมรับข่าวสารสัมพันธ์อิเล็กทรอนิกส์กับเรา
                </p>
              </div>
            </div>
            <div className="col-33">
              <div className="email-contact-bt">
                <div className="main-bt-email-contact">
                  <Link to={"/Email"} className="">
                    <button className="email-bt-1">อีเมล์</button>
                  </Link>
                </div>
                <div className="main-bt-email-contact">
                  <Link to={"/Contact"} className="">
                    <button className="contact-bt-2">ติดต่อเรา</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-row layout-email">
            <div className="col-66">
              <div className="email-contact-text">
                <p className="t1">Email & Contact Investor Relations</p>
                <p className="t2">
                  Information regarding contacting Investor Relations of
                  s.kijchai enterprise public company limited.
                </p>
              </div>
            </div>
            <div className="col-33">
              <div className="email-contact-bt">
                <div className="main-bt-email-contact">
                  <Link to={"/Email"} className="">
                    <button className="email-bt-1">Email</button>
                  </Link>
                </div>
                <div className="main-bt-email-contact">
                  <Link to={"/Contact"} className="">
                    <button className="contact-bt-2">Contact us</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
