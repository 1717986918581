export const dataYoY = {
  series: [
    {
      name: "สินทรัพย์รวม",
      data: [4.84, 4.43],
      colors: "rgb(94, 143, 181)",
    },
    {
      name: "หนี้สินรวม",
      data: [1.08, 0.81],
    },
    {
      name: "ส่วนของผู้ถือหุ้น",
      data: [3.75, 3.62],
    },
    {
      name: "กำไรสุทธิ",
      data: [0.42, 0.68],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: "100%",
      width: "100%",
      fontFamily: "Noto Sans Thai",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["2566", "2565"],
    },
    yaxis: {
      title: {
        text: "พันล้านบาท",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "rgb(94, 143, 181)",
      "rgb(236, 214, 68)",
      "rgb(59, 142, 75)",
      "rgb(159, 138, 112)",
    ],

    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Billion Bath";
        },
      },
    },
  },
};

export const dataQoQ = {
  series: [
    {
      name: "สินทรัพย์รวม",
      data: [4.56, 4.46],
      colors: "rgb(94, 143, 181)",
    },
    {
      name: "หนี้สินรวม",
      data: [0.64, 0.79],
    },
    {
      name: "ส่วนของผู้ถือหุ้น",
      data: [3.92, 3.67],
    },
    {
      name: "กำไรสุทธิ",
      data: [0.16, 0.05],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: "100%",
      width: "100%",
      fontFamily: "Noto Sans Thai",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["3M/2567", "3M/2566"],
    },
    yaxis: {
      title: {
        text: "พันล้านบาท",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "rgb(94, 143, 181)",
      "rgb(236, 214, 68)",
      "rgb(59, 142, 75)",
      "rgb(159, 138, 112)",
    ],

    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Billion Bath";
        },
      },
    },
  },
};

export const dataYoYEN = {
  series: [
    {
      name: "Total income",
      data: [4.84, 4.43],
      colors: "rgb(94, 143, 181)",
    },
    {
      name: "Par value",
      data: [1.08, 0.81],
    },
    {
      name: "Assets",
      data: [3.75, 3.62],
    },
    {
      name: "Equity",
      data: [0.42, 0.68],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: "100%",
      width: "100%",
      fontFamily: "Noto Sans Thai",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["2023", "2022"],
    },
    yaxis: {
      title: {
        text: "(Billion baht)",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "rgb(94, 143, 181)",
      "rgb(236, 214, 68)",
      "rgb(59, 142, 75)",
      "rgb(159, 138, 112)",
    ],

    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Billion Bath";
        },
      },
    },
  },
};

export const dataQoQEN = {
  series: [
    {
      name: "Total income",
      data: [4.56, 4.46],
      colors: "rgb(94, 143, 181)",
    },
    {
      name: "Par value",
      data: [0.64, 0.79],
    },
    {
      name: "Assets",
      data: [3.92, 3.67],
    },
    {
      name: "Equity",
      data: [0.16, 0.05],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: "100%",
      width: "100%",
      fontFamily: "Noto Sans Thai",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["3M/2024", "3M/2023"],
    },
    yaxis: {
      title: {
        text: "(Billion baht)",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "rgb(94, 143, 181)",
      "rgb(236, 214, 68)",
      "rgb(59, 142, 75)",
      "rgb(159, 138, 112)",
    ],

    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Billion Bath";
        },
      },
    },
  },
};
