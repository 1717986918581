export const irCalendarData = [
  {
    date: "16 สิงหาคม 2566",
    time: "16.15-17.00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2566",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "oppdayq2_skn_2023",
    year: 2566,
  },
  {
    date: "16 สิงหาคม 2566",
    time: "16.15-17.00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2566",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "",
    year: 2566,
  },
  {
    date: "24 เมษายน 2566",
    time: "14.00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2566",
    location: "ประชุมผ่านสื่ออิเล็กทรอนิกส์ (e-AGM)",
    relateInfo: "",
    year: 2566,
  },
  {
    date: "4 เมษายน 2566",
    time: "14.15 - 15.00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2565",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "oppday_skn_2022",
    year: 2566,
  },
  {
    date: "17 สิงหาคม 2565",
    time: "16:15 - 17:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2565",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "SKN_OPPDAY2Q22_170822",
    year: 2565,
  },
  {
    date: "26 เมษายน 2565",
    time: "14 : 00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2565",
    location: "ประชุมผ่านสื่ออิเล็กทรอนิกส์ (E-AGM)",
    relateInfo: "",
    year: 2565,
  },
  {
    date: "4 มีนาคม 2565",
    time: "13.15 - 14.00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2564",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "SKN_OPPDAY4Q21_040321",
    year: 2565,
  },
  {
    date: "18 พฤศจิกายน 2564",
    time: "10:15 - 11:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 3/2564",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "SKN_OPPDAY3Q21_181121",
    year: 2564,
  },
  {
    date: "16 สิงหาคม 2564",
    time: "13:15 - 14:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 2/2564",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "SKN_OPPDAY2Q21_160821",
    year: 2564,
  },
  {
    date: "26 เมษายน 2564",
    time: "14.00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2564",
    location:
      "โรงแรมวีว่า การ์เด้น เซอร์วิส เรสซิเดนซ์ เลขที่ 1988 ถนนสุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร 10260",
    relateInfo: "",
    year: 2564,
  },
  {
    date: "29 มีนาคม 2564",
    time: "11:15 - 12:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุนสำหรับผลประกอบการไตรมาส 4/2563",
    location: "รับชมผ่านช่องทางออนไลน์",
    relateInfo: "",
    year: 2564,
  },
  {
    date: "20 สิงหาคม 2563",
    time: "09:15 - 10:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุน สำหรับผลประกอบการไตรมาส 2/2563",
    location:
      "ฟังสด ณ อาคารตลาดหลักทรัพย์ฯ รับชมผ่านช่องทางออนไลน์เท่านั้น ไม่อนุญาตให้เข้าฟังสด ณ อาคารตลาดหลักทรัพย์ฯ",
    relateInfo: "SKN_OPPDAY_200820",
    year: 2563,
  },
  {
    date: "16 กันยายน 2563",
    time: "14.00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2563",
    location: "โรงแรมวีว่า การ์เด้น เซอร์วิส เรสซิเดนซ์",
    relateInfo: "",
    year: 2563,
  },
  {
    date: "24 เมษายน 2563",
    time: "14.00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2563",
    location:
      "โรงแรมวีว่า การ์เด้น เซอร์วิส เรสซิเดนซ์ 1988 ถนนสุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร 10260",
    relateInfo: "",
    year: 2563,
  },
  {
    date: "26 เมษายน 2562",
    time: "14.00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2562",
    location:
      "โรงแรมวีว่า การ์เด้น เซอร์วิส เรสซิเดนซ์ เลขที่ 1988 ถนนสุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร 10260",
    relateInfo: "",
    year: 2562,
  },
  {
    date: "15 มิถุนายน 2561",
    time: "13:00 - 14:00",
    event: "บริษัทจดทะเบียนพบผู้ลงทุน สำหรับผลประกอบการไตรมาส 1/2561",
    location:
      "ณ ห้องประชุม 603 อาคารตลาดหลักทรัพย์แห่งประเทศไทย 93 ถนนรัชดาภิเษก แขวง ดินแดง เขต ดินแดง กรุงเทพมหานคร",
    relateInfo: "SKN_OPPDAY_150618",
    year: 2561,
  },
  {
    date: "24 เมษายน 2561",
    time: "14:00",
    event: "การประชุมสามัญผู้ถือหุ้นประจำปี 2561",
    location:
      "ณ ห้อง Meeting Room 4 ชั้น 2 (Zone B) ศูนย์การประชุมแห่งชาติสิริกิติ์ เลขที่ 60 ถนนรัชดาภิเษกตัดใหม่ เขตคลองเตย กรุงเทพมหานคร",
    relateInfo: "",
    year: 2561,
  },
];

export const irCalendarDataEN = [
  {
    date: "16 August 2023",
    time: "16.15-17.00",
    event: "Opportunity Day Quarter 2/2023",
    location: "Virtual Conference",
    relateInfo: "oppdayq2_skn_2023",
    year: 2023,
  },
  {
    date: "16 August 2023",
    time: "16.15-17.00",
    event: "Opportunity Day Quarter 2/2023",
    location: "Virtual Conference",
    relateInfo: "",
    year: 2023,
  },
  {
    date: "24 April 2023",
    time: "14.00",
    event: "Annual General Meeting of 2023",
    location: "Electronic Meeting (e-AGM)",
    relateInfo: "",
    year: 2023,
  },
  {
    date: "4 April 2023",
    time: "14.15 - 15.00",
    event: "Opportunity Day Quarter 4/2022",
    location: "Virtual Conference",
    relateInfo: "oppday_skn_2022",
    year: 2023,
  },
  {
    date: "17 August 2022",
    time: "16:15 - 17:00",
    event: "Opportunity Day Quarter 2/2022",
    location: "Virtual Conference",
    relateInfo: "SKN_OPPDAY2Q22_170822",
    year: 2022,
  },
  {
    date: "26 April 2022",
    time: "14 : 00",
    event: "Annual General Meeting of Shareholders 2022",
    location: "Conducted through Electronic Meeting ",
    relateInfo: "",
    year: 2022,
  },
  {
    date: "4 March 2022",
    time: "13.15 - 14.00",
    event: "Opportunity Day Quarter 4/2021",
    location: "Virtual Conference",
    relateInfo: "SKN_OPPDAY4Q21_040321",
    year: 2022,
  },
  {
    date: "18 November 2021",
    time: "10:15 - 11:00",
    event: "Opportunity Day Quarter 3/2021",
    location: "Virtual Conference",
    relateInfo: "SKN_OPPDAY3Q21_181121",
    year: 2021,
  },
  {
    date: "16 August 2021",
    time: "13:15 - 14:00",
    event: "Opportunity Day Quarter 2/2021",
    location: "Virtual Conference",
    relateInfo: "SKN_OPPDAY2Q21_160821",
    year: 2020,
  },
  {
    date: "26 April 2021",
    time: "14.00",
    event: "Annual General Meeting of Shareholders 2021",
    location:
      " VIVA GARDEN SERVISE RESIDENT1988 Sukhumvit Road, Bang Chak Sub-District, Phra Khanong District, Bangkok 10260.",
    relateInfo: "",
    year: 2020,
  },
  {
    date: "29 March 2021",
    time: "11:15 - 12:00",
    event: "Opportunity Day Quarter 4/2020",
    location: "Virtual Conference",
    relateInfo: "",
    year: 2020,
  },
  {
    date: "20 August 2020",
    time: "09:15 - 10:00",
    event: "Opportunity Day Quarter 2/2020",
    location:
      "ฟังสด ณ อาคารตลาดหลักทรัพย์ฯ รับชมผ่านช่องทางออนไลน์เท่านั้น ไม่อนุญาตให้เข้าฟังสด ณ อาคารตลาดหลักทรัพย์ฯ",
    relateInfo: "SKN_OPPDAY_200820",
    year: 2019,
  },
  {
    date: "16 September 2020",
    time: "14.00",
    event: "Annual General Meeting of Shareholders 2020",
    location: "Viva Garden Serviced Resident",
    relateInfo: "",
    year: 2019,
  },
  {
    date: "24 April 2020",
    time: "14.00",
    event: "Annual General Meeting of Shareholders 2020",
    location:
      "VIVA GARDEN SERVISE RESIDENT, 1988 Sukhumvit Road, Bangjak Sub District, Phrakanong District Bangkok 10260",
    relateInfo: "",
    year: 2019,
  },
  {
    date: "26 April 2019",
    time: "14.00",
    event: "Annual General Meeting of Shareholders 2019",
    location:
      "VIVA GARDEN SERVISE RESIDENT, 1988 Sukhumvit Road, Bangjak Sub District, Phrakanong District Bangkok 10260",
    relateInfo: "",
    year: 2018,
  },
  {
    date: "15 June 2018",
    time: "13:00 - 14:00",
    event: "Opportunity Day Quarter 1/2018",
    location:
      "Meeting Room 603 at The Stock Exchange of Thailand Building 93 Ratchadaphisek Road, Dindaeng, Dindaeng, Bangkok",
    relateInfo: "SKN_OPPDAY_150618",
    year: 2017,
  },
  {
    date: "24 April 2018",
    time: "14:00",
    event: "Annual General Meeting of Shareholders 2018",
    location:
      "at Meeting Room 4, 2nd Floor (Zone B), Queen Sirikit National Convention Center, 60 Ratchadaphisek Rd, Khet KhlongToei, Bangkok",
    relateInfo: "",
    year: 2017,
  },
];
