import axios from "axios";
import instance from "./apiService";
const timeout = 1000000; // 5 วิ
const companyId = "5071742a-77fc-48ca-8818-21605cee4bf6";

export const getNews = async (language, currentPage, pageSize = 20, newsId) => {
  let source = axios.CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);
  const response = await instance
    .get(`ir/companies/${companyId}/news/setNews`, {
      params: {
        Langauge: language,
        PageNumber: currentPage,
        pageSize: pageSize,
        DocType: "e",
        NewsId: newsId,
        // year: year,
      },
      cancelToken: source.token,
    })
    .then((res) => {
      return { status: true, result: res };
    })
    .catch((error) => {
      return { status: false, result: error };
    });
  return response;
};

export const getDateSetRealtime = async () => {
  let source = axios.CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);

  const response = await instance
    .get(`/ir/companies/${companyId}/SET/Realtime`, {
      cancelToken: source.token,
    })
    .then((res) => {
      return { status: true, result: res };
    })
    .catch((err) => {
      return { status: false, result: err };
    });
  return response;
};
